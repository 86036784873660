import { OrderLineItemScanDTO } from "../services/scanner/scanner";
import parse from 'html-react-parser';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('xml', xml);
import validator from 'validator';

export const getAllLineItemsScanned = (scans: OrderLineItemScanDTO[]) => {
    let result = true;

    scans.forEach((scan: OrderLineItemScanDTO) => {
        if (!scan.scanned) result = false;
    });

    return result ? 'All Scanned' : 'Not All Scanned';
}

export const prettifyXml = (sourceXml: string) => {
    sourceXml = sourceXml.replace(/&/g, "&amp;");
    const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    const xsltDoc = new DOMParser().parseFromString([
    // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    const xsltProcessor = new XSLTProcessor();    
    xsltProcessor.importStylesheet(xsltDoc);
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};

export const enhanceJDML = (text: string): string => {
    text = text.replace(/&/g, "&amp;");
    // const prettyText = prettifyXml(text);
    const prettyText = text;
    const highlightedText = hljs.highlight(prettyText, {language: 'xml'}).value;
    const regex = /(?<=\$)(.*)(?=\$)/g;
    const variables = prettyText.match(regex);
    const splitArray = highlightedText.split('$');
    const variableText = splitArray.map((item) => {
        if (variables && variables.includes(item)) {
            return `<span class="text-purple-500 dark:text-purple-400">$${item}$</span>`;
        }
        return item;
    }).join('');
    const decodedText = variableText.replace("&amp;", '&');
    return parse(decodedText.trim()) as string;
}

export const validatePassword = (password: string): boolean => {
    if (!validator.isStrongPassword(password, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
        })) {
        return false;
        
    }
    return true;
}