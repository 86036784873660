import React, { PropsWithChildren } from "react";
import Loader from "../../loader/Loader.component";


export interface SpinnerProps {
    show: boolean;
    size?: number;
}

type SpinnerType = PropsWithChildren<SpinnerProps>;

export const Spinner: React.FunctionComponent<SpinnerType> = (props: SpinnerType): JSX.Element => {
    const { show, size = 50 } = props;

    return (
        <>
            {
                show ? <div role="status">
                    <Loader size={size} />
                    <span className="sr-only">Loading...</span>
                </div> : null
            }
        </>
    );
}