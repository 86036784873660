import React from 'react';
import { useForm } from "../../hooks/useForm";
import { Button, Field, Input, Label, Spinner, Text } from '../../components/UI';
import { Snackbar } from '../../components/UI/snackbar/Snackbar.component';
import { useSnackbar } from '../../hooks/useSnackbar';
import { ForgotPasswordRequestBody, useUsers } from '../../services/login/authentication';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validatePassword } from '../../globals/helpers';

const bucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';
const imageLink = `https://${bucket}.s3.us-west-1.amazonaws.com/dashboard-images/4905311699007.jpg`;

const ResetPassword: React.FunctionComponent<{}> = (): JSX.Element => {

    const [,,AuthenticationFunctions] = useUsers();
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [searchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useSnackbar();

    const navigate = useNavigate();

    const form = useForm([
        {
            key: 'password',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
    ]);

    const submitForm = async () => {
        setShowSpinner(true);

        if (form.valueAtKey('password') == form.valueAtKey('confirm_password')) {
            if (validatePassword(form.valueAtKey('password'))) {
                const addValue: ForgotPasswordRequestBody = {
                    id: parseInt(searchParams.get('user_id') as string),
                    reset_token: searchParams.get('verify_token') as string,
                    send_reset_email: false,
                    password: form.valueAtKey('password'),
                }

                await AuthenticationFunctions.forgotPassword(addValue);
                navigate('/login');
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'warn',
                    text: 'Password is invalid. Please enter a password with the required parameters.',
                });
            }
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'warn',
                text: 'Passwords do not match. Please assure that both passwords are the same.',
            });
        }

        setShowSpinner(false);
    }

    return(
        <div className="w-screen h-screen relative">
            <div className="absolute t-0 l-0 w-full h-full z-0">
                <img 
                    src={imageLink}
                    className="w-full h-full"
                />
            </div>
            <div className="absolute t-0 l-0 w-full h-full z-20 bg-black/30"></div>
            <div className="absolute t-0 l-0 w-full h-full z-40">
                <div className="flex flex-row justify-center items-center w-full h-full">
                    <div className="w-full md:w-1/2 lg:w-2/3 xl:w-1/3 h-full md:h-auto lg:h-auto bg-slate-50 dark:bg-slate-800 shadow-lg border-2 border-slate-400 rounded-lg min-w-64">
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                            <h1 className="text-4xl text-gray-900 dark:text-white mb-5 text-center">Reset Password</h1>
                            <Field className="mb-5">
                                <Label>Password</Label>
                                <Input type='password' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('password', ev.target.value)} maxLength={64}></Input>
                            </Field>
                            <Field>
                                <Label>Confirm Password</Label>
                                <Input type='password' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('confirm_password', ev.target.value)} maxLength={64}></Input>
                            </Field>
                            <p className='text-yellow-500 mt-5'>Password must contain at least 8 characters, one lowercase character, one uppercase character, one number and one special character.</p>

                            <div className="flex flex-row justify-between">
                                <Text className="!font-bold pt-6 cursor-pointer" onClick={() => navigate('/login')}>Back To Login</Text>
                                <div className="flex justify-end border-stone-700 pt-6 gap-x-4">
                                    <Spinner show={showSpinner}></Spinner>
                                    <Button onClick={ submitForm } color="emerald">Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar 
                colorType={snackbar.snackbarLevel} 
                show={snackbar.show} 
                showUpdate={(value: boolean) => {
                    setSnackbar({ text: snackbar.text, show: value, snackbarLevel: snackbar.snackbarLevel, duration: 5 });
                }}
                text={snackbar.text}
                dissapearIn={snackbar.duration ? snackbar.duration : 5}
            />
        </div>
    )
}

export default ResetPassword;