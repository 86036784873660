import React from "react";
import { Bars3Icon, CogIcon } from '@heroicons/react/24/outline';
import { RouteConstants } from "../../../Routes";
import { Link } from "../../UI";

export interface StickyBarProps {
    setSidebarOpen: (value: boolean) => void;
}

export const StickyBar: React.FunctionComponent<StickyBarProps> = (props): JSX.Element => {
    const { setSidebarOpen } = props;
    return (
        <>
            <div className="
                sticky top-0 z-40 flex items-center gap-x-6 px-4 py-4 shadow-sm sm:px-6 lg:hidden
                dark:bg-stone-900 
                bg-stone-200
            ">

                <button type="button" className="-m-2.5 p-2.5 lg:hidden dark:text-gray-400 text-gray-700" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="
                    flex-1 text-sm font-semibold leading-6 
                    dark:text-white
                    text-black
                ">
                    Dashboard
                </div>

                <Link href={RouteConstants.SETTINGS_HOME}>
                    <span className="sr-only">Settings</span>
                    <CogIcon className="w-6 h-6 shrink-0 dark:text-white text-black" />
                </Link>
            </div> 
        </>
    )
}