import { atom, useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import Axios, { AxiosResponse } from "axios";
import { errorCatcher, useSnackbar } from "../../hooks/useSnackbar";
import { useLogin } from "../login/login";

export interface Collection {
    id?: number;
    name: string;
    artist_name: string;
    font_id: number;
    brand_id: number;
    collection_logo: string;
    secondary_image: string;
    signature: string;
    use_signature_image: boolean;
    message: string;
}

export interface UpdateCollectionRequestBody {
    id?: number;
    name: string;
    artist_name: string;
    font_id: number;
    use_signature_image: boolean;
    brand_id: number;
    message: string;
    collection_logo: string;
    secondary_image: string;
    signature: string;
}

export const collectionsAtom = atom<Collection[]>([]);
export const collectionsLoadingAtom = atom<boolean>(false);
export const shopifyCollectionsAtom = atom<Collection[]>([]);
export const shopifyCollectionsLoadingAtom = atom<boolean>(false);

type SetCollectionType = (payload: Collection[]) => void;
type SetShopifyCollectionType = (payload: Collection[]) => void;
type FetchCollectionType = (brandId: number) => void;
type FetchShopifyCollectionType = (brandId: number) => void;
type DeleteCollectionType = (mockup_id: number) => Promise<boolean>;
type AddCollectionType = (body: UpdateCollectionRequestBody) => Promise<Collection>;
type UpdateCollectionType = (body: UpdateCollectionRequestBody) => Promise<Collection>;

type CollectionKeyFunctions = {
    get: FetchCollectionType;
    getShopify: FetchShopifyCollectionType;
    delete: DeleteCollectionType;
    add: AddCollectionType;
    update: UpdateCollectionType;
}

type UseCollections = [
    Collection[],
    Collection[],
    SetCollectionType,
    SetShopifyCollectionType,
    CollectionKeyFunctions
]

export const useCollections = (): UseCollections => {
    const [collections, setCollections] = useAtom(collectionsAtom);
    const [shopifyCollections, setShopifyCollections] = useAtom(shopifyCollectionsAtom);
    const setCollectionsLoading = useSetAtom(collectionsLoadingAtom);
    const setShopifyCollectionsLoading = useSetAtom(shopifyCollectionsLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getCollections = (brandId: number) => {
        setCollectionsLoading(true);
        const fetchCollections = async (setter: SetCollectionType): Promise<void> => {
            const results = await Axios.get(`${environment.collectionsUrl}?brand_id=${brandId}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting collections. Please try again later.',
                })
            }
            setCollectionsLoading(false);
        }
        // send and forget
        fetchCollections(setCollections);
    }

    const getShopifyCollections = (brandId: number) => {
        setShopifyCollectionsLoading(true);
        const fetchCollections = async (setter: SetShopifyCollectionType): Promise<void> => {
            setter((await Axios.get(`${environment.collectionsUrl + '/shopify'}?brand_id=${brandId}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }

            })).data);
            setShopifyCollectionsLoading(false);
        }
        fetchCollections(setShopifyCollections);
    }

    const addCollection = async (body: UpdateCollectionRequestBody): Promise<Collection> => {
        const insertResponse: AxiosResponse<Collection> = await Axios.post(environment.collectionsUrl, body, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        setCollections([...collections, insertResponse.data]);
        setShopifyCollections([...shopifyCollections]);

        
        return insertResponse.data;
    }

    const deleteCollection = async (collectionId: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> = await Axios.delete(`${environment.collectionsUrl}?collection_id=${collectionId}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });
        if (deleteResponse.data) {
            setCollections(collections.filter((item: Collection) => item.id !== collectionId));
        }
        return deleteResponse.data;
    }

    const updateCollection = async(body: UpdateCollectionRequestBody): Promise<Collection> => {
        const updateResponse: AxiosResponse<Collection> = await Axios.put(environment.collectionsUrl, body, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        setCollections(collections.map((collection: Collection) => {
            if (collection.id === body.id) {
                collection.collection_logo = updateResponse.data.collection_logo;
            }
            return collection;
        }))
        return updateResponse.data;
    }

    const keyFunctions: CollectionKeyFunctions = {
        add: addCollection,
        delete: deleteCollection,
        get: getCollections,
        getShopify: getShopifyCollections,
        update: updateCollection
    }

    return [
        collections,
        shopifyCollections,
        setCollections,
        setShopifyCollections,
        keyFunctions
    ]
}