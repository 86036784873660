
export interface WallmatesLogoProps {
    width?: number;
    height?: number;
}

export const WallmatesLogo: React.FunctionComponent<WallmatesLogoProps> = (props: WallmatesLogoProps) => {
    const { width, height = 128 } = props;
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 720" width={width} height={height}>
            <path className="fill-black dark:fill-white" d="M318.87,414.65c-8.72-1.07-15.02-8.96-15.02-17.74v-72.91c0-32.94-25.56-61.09-58.48-62.27-34.48-1.24-62.93,26.45-62.93,60.66v74.52c0,8.79-6.29,16.67-15.02,17.74-10.43,1.28-19.33-6.86-19.33-17.04v-135.34c0-.07-.06-.12-.12-.12h-43.28c-.07,0-.12.06-.12.12v133.73c0,32.94,25.56,61.09,58.48,62.27,34.48,1.24,62.93-26.45,62.93-60.66v-74.52c0-8.79,6.29-16.67,15.01-17.74,10.43-1.28,19.33,6.86,19.33,17.04v73.54c0,28.58,19.12,54.28,46.95,60.79,7.83,1.83,15.43,2,22.62.91v-45.37c-3.16,1.92-6.97,2.89-11.03,2.39Z"/>
            <path className="fill-black dark:fill-white" d="M552.5,261.72c-32.91,1.19-58.47,29.33-58.47,62.27v72.93c0,8.78-6.29,16.67-15.01,17.74-10.43,1.28-19.33-6.86-19.33-17.04v-73.37c0-27.26-17.33-52.02-43.41-59.95-9.11-2.77-17.9-3.18-26.17-1.92v45.37c2.91-1.77,6.37-2.74,10.08-2.48,9.15.63,15.96,8.79,15.96,17.96v72.79c0,32.94,25.56,61.08,58.47,62.27,34.49,1.24,62.94-26.45,62.94-60.66v-74.53c0-8.78,6.29-16.67,15.01-17.74,10.43-1.28,19.34,6.86,19.34,17.04v135.35c0,.06.05.12.12.12h43.3c.06,0,.12-.05.12-.12v-135.35c0-34.21-28.45-61.91-62.94-60.66Z"/>
            <path className="fill-black dark:fill-white" d="M338.27,322.39v133.4c25.09-7.45,43.45-30.69,43.45-58.17v-133.4c-25.09,7.45-43.45,30.69-43.45,58.17Z"/>
        </svg>
    )
}