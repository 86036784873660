import Axios, { AxiosResponse } from 'axios';
import { environment } from '../../environment';
import { atom, useAtom } from 'jotai';
import { errorCatcher, useSnackbar } from '../../hooks';
import { useLogin } from '../login/login';

export interface TranslationLanguage {
    id?: number;
    name: string;
    language_key: string;
    language_image: string;
}

const translationLanguageAtom = atom<TranslationLanguage[]>([]);

type SetTranslationType = (payload: TranslationLanguage[]) => void;
type FetchTranslationTypes = () => void;
type SaveTranslationType = (payload: TranslationLanguage) => void;
type DeleteTranslationType = (language_id: number) => Promise<boolean>;
type UpdateTranslationType = (payload: TranslationLanguage) => void;

type TranslationLanguageKeyFunctions = {
    get: FetchTranslationTypes;
    add: SaveTranslationType;
    delete: DeleteTranslationType;
    update: UpdateTranslationType;
}

type UseTranslationLanguages = [
    TranslationLanguage[], 
    SetTranslationType,
    TranslationLanguageKeyFunctions
];

export const useTranslationLanguages = (): UseTranslationLanguages => {
    const [translationLanguages, setTranslationLanguages] = useAtom(translationLanguageAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getTranslationLanguages = async (setTranslationLanguages: SetTranslationType): Promise<void> => {
        const languages: AxiosResponse<TranslationLanguage[]> | void = await Axios.get(environment.languagesUrl, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (languages) {
            setTranslationLanguages(languages.data);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was and issue getting translation languages. Please try again later.'
            });
        }
    }
    
    const saveTranslationLanguage = async (translationLanguage: TranslationLanguage): Promise<TranslationLanguage | null> => {
        const language: AxiosResponse<TranslationLanguage> | void = await Axios.post(environment.languagesUrl, {
            language: translationLanguage
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (language) {
            return language.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was and issue creating a translation language. Please try again later.'
            });
            return null;
        }
    }
    
    const deleteTranslationLanguage = async (language_id: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> | void = await Axios.delete(`${environment.languagesUrl}?language_id=${language_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (deleteResponse) {
            return deleteResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was and issue deleting a translation language. Please try again later.'
            });
            return false;
        }
    }
    
    const updateTranslationLanguage = async (translationLanguage: TranslationLanguage): Promise<TranslationLanguage | null> => {
        const language: AxiosResponse<TranslationLanguage> | void = await Axios.put(environment.languagesUrl, {
            language: translationLanguage
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        
        if (language) {
            return language.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was and issue updating a translation language. Please try again later.'
            });
            return null;
        }
    }

    const fetchTranslations = () => {
        getTranslationLanguages(setTranslationLanguages);
    }

    const saveLanguage = async (language: TranslationLanguage): Promise<void> => {
        const value = await saveTranslationLanguage(language);
        if (value) {
            setTranslationLanguages([...translationLanguages, value]);
        }
    }

    const deleteLanguage = async (language_id: number): Promise<boolean> => {
        const result = await deleteTranslationLanguage(language_id);
        setTranslationLanguages(translationLanguages.filter((item: TranslationLanguage) => item.id !== language_id))
        return result;
    }

    const updateLanguage = async (language: TranslationLanguage): Promise<void> => {
        await updateTranslationLanguage(language);
        setTranslationLanguages(
            translationLanguages.map((item: TranslationLanguage) => {
                if (item.id === language.id) return language;
                return item;
            })
        )
    }

    const keyFunctions: TranslationLanguageKeyFunctions = {
        add: saveLanguage,
        delete: deleteLanguage,
        get: fetchTranslations,
        update: updateLanguage
    }

    return [
        translationLanguages, 
        setTranslationLanguages, 
        keyFunctions
    ];
}