import { atom, useAtom, useSetAtom } from "jotai";
import Axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login/login";


export interface TranslationKey {
    id?: number;
    key_name: string;
}

export interface Translation {
    id?: number;
    translation_key_id: number;
    language_id: number;
    translation_text: string;    
}

export interface InsertTranslationRequest {
    translation_key_text: string,
    translations: {
        [key: string]: string
    }
}

export interface UpdateTranslationRequest {
    translation_key: TranslationKey;
    translations: Translation[];
}

export const translationsAtom = atom<{[key: string]: string}>({});
export const translationsLoadingAtom = atom<boolean>(false);

const translationKeysAtom = atom<TranslationKey[]>([]);

type SetTranslationKeyType = (payload: TranslationKey[]) => void;
type FetchTranslationKeysType = () => void;
type DeleteTranslationKeyType = (key_id: number) => Promise<boolean>;
type AddTranslationKeyType = (insertType: InsertTranslationRequest) => void;
type UpdateTranslationKeyType = (updateType: UpdateTranslationRequest) => void;
type GetTranslationsByKeyType = (key_id: number) => Promise<Translation[] | null>;
type GetTranslationsByLanguageId = (language_id: number) => Promise<{[key: string]: string} | null>;

type TranslationKeyFunctions = {
    get: FetchTranslationKeysType;
    delete: DeleteTranslationKeyType;
    add: AddTranslationKeyType;
    update: UpdateTranslationKeyType;
    getTranslationsByKey: GetTranslationsByKeyType;
    getTranslationsByLanguageId: GetTranslationsByLanguageId
}

type UseTranslationlanguageKeys = [
    TranslationKey[],
    SetTranslationKeyType,
    TranslationKeyFunctions
];

export const useTranslationLanguageKeys = (): UseTranslationlanguageKeys => {
    const [translationKeys, setTranslationKeys] = useAtom(translationKeysAtom);
    const setTranslationsLoading = useSetAtom(translationsLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getTranslationKeys = async (): Promise<TranslationKey[] | null> => {
        setTranslationsLoading(true);
        return await Axios.get(environment.translationKeysUrl, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
          .then((response) => {
              if (response.data) {
                  return response.data;
              }
              setSnackbar({
                  show: true,
                  snackbarLevel: 'error',
                  text: 'There was an issue while getting translation keys. Please try again later.'
              });
              return null;
          })
          .catch((err: any) => errorCatcher(err, loginFunctions.logout))
          .finally(() => setTranslationsLoading(false));
    }

    const deleteTranslationKey = async (translation_key_id: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> | void = await Axios.delete(`${environment.translationKeysUrl}?translation_key_id=${translation_key_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (deleteResponse) {
            return deleteResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an issue while deleting a translation key. Please try again later.'
            });
            return false;
        }
    }

    const addTranslationKey = async (inputType: InsertTranslationRequest): Promise<TranslationKey | null> => {
        const insertResponse: AxiosResponse<TranslationKey> | void = await Axios.post(`${environment.translationUrl}`, inputType, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (insertResponse) {
            return insertResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an issue while adding a translation key. Please try again later.'
            });
            return null;
        }
    }

    const updateTranslationKey = async (updateType: UpdateTranslationRequest): Promise<TranslationKey | null> => {
        const updateResponse: AxiosResponse<TranslationKey> | void = await Axios.put(`${environment.translationUrl}`, updateType, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (updateResponse) {
            return updateResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an issue while updating a translation key. Please try again later.'
            });
            return null;
        }
    }

    const getTranslationsByKey = async (key_id: number): Promise<Translation[] | null> => {
        const getResponse: AxiosResponse<Translation[]> | void = await Axios.get(`${environment.translationUrl}?key_id=${key_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getResponse) {
            return getResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an issue while getting a specific translation key. Please try again later.'
            });
            return null;
        }
    }

    const getTranslationsByLanguageId = async (language_id: number): Promise<{[key: string]: string} | null> => {
        const getTranslationsResponse: AxiosResponse<{[key: string]: string}> | void = await Axios.get(`${environment.translationUrl}?language_id=${language_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getTranslationsResponse) {
            return getTranslationsResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an issue while getting a translation response. Please try again later.'
            });
            return null;
        }
    }

    const getTranslations = async () => {
        const result = await getTranslationKeys();
        if (result) {
            setTranslationKeys(result);
        }
    }

    const deleteKey = async (key_id: number) => {
        const result = await deleteTranslationKey(key_id);
        setTranslationKeys(translationKeys.filter((item: TranslationKey) => item.id !== key_id));
        return result;
    }

    const addKey = async (inputType: InsertTranslationRequest): Promise<void> => {
        const value = await addTranslationKey(inputType);
        if (value) {
            setTranslationKeys([...translationKeys, value]);
        }
    }

    const updateKey = async (updateType: UpdateTranslationRequest): Promise<void> => {
        const result = await updateTranslationKey(updateType);
        if (result) {
            setTranslationKeys(translationKeys.map((item: TranslationKey) => {
                if (item.id === updateType.translation_key.id) {
                    return updateType.translation_key
                }
                return item;
            }));
        }
    }

    const keyFunctions: TranslationKeyFunctions = {
        add: addKey,
        delete: deleteKey,
        get: getTranslations,
        update: updateKey,
        getTranslationsByKey: getTranslationsByKey,
        getTranslationsByLanguageId: getTranslationsByLanguageId
    }

    return [
        translationKeys,
        setTranslationKeys,
        keyFunctions
    ]
}
