import { useEffect, useState } from 'react';

export const useDebounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
): ((...args: Parameters<F>) => void) => {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (...args: Parameters<F>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      func(...args);
    }, delay);

    setTimeoutId(id);
  };
};