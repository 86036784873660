import { atom, useAtom, useSetAtom } from "jotai";
import Axios, { AxiosResponse } from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks/useSnackbar";
import { useLogin } from "./login";

export interface UserWILLVERSION {
    brand_id: number;
    verify_token?: string;
    first: string;
    last: string;
    password: string;
}

export interface CreateUserRequestBody {
    id: number;
    verify_token: string;
    first: string;
    last: string;
    password: string;
}

export interface CreateAccountRequestBody {
    brand_id: number;
    is_new_brand: boolean;
    brand: {name: string};
    first: string;
    last: string;
    email: string;
    password: string;
    permission: number;
}

export interface VerifyUserRequestBody {
    user_id: number;
    verify_token: string;
}

export interface UpdateUserRequestBody {
    user_id: number;
    permission: number;
}

export interface CreateNewUserRequestBody {
    email?: string;
    first?: string;
    last?: string;
    password?: string;
    is_new_brand: boolean;
    brand: {
        id: number;
        name?: string;
    },
    permission: number;
}

export interface ForgotPasswordRequestBody {
    email?: string;
    send_reset_email: boolean;
    id?: number;
    reset_token?: string;
    password?: string;
}

export interface User {
    id?: number;
    brand_id: number;
    first: string;
    last: string;
    email: string;
    email_verified: boolean;
    password?: string;
    salt?: string;
    permissions?: UserPermission;
    temporary_token?: string;
}

export interface UserPermission {
    id?: number;
    permission_id: number | Permission;
    created_at: string;
    user_id: number;
}

export interface Permission {
    id?: number;
    created_at: string;
    permission: 'USER' | 'ADMIN' | 'TECHNICIAN' | 'SUPERUSER';
    display_name: string;
    is_publicly_visible: boolean;
}

export const usersAtom = atom<User[]>([]);
export const usersAtomLoading = atom<boolean>(false);

export const permissionsAtom = atom<Permission[]>([]);

type GetUsersType = (brand_id: number) => Promise<User[]>;
type GetPermissionsType = () => Promise<Permission[]>;
type AddAccountType = (body: CreateAccountRequestBody) => Promise<void>;
type UpdateUserType = (body: CreateUserRequestBody) => Promise<User>;
type DeleteUserType = (user_id: number) => Promise<User | null>;
type UpdateUserPermissionsType = (body: UpdateUserRequestBody) => Promise<User | null>;
type CreateUserType = (body: CreateNewUserRequestBody) => Promise<User | null>;
type VerifyUserType = (body: VerifyUserRequestBody) => Promise<void>;
type ForgotPasswordType = (body: ForgotPasswordRequestBody) => Promise<void>;

type AuthenticationKeyFunctions = {
    add: AddAccountType;
    update: UpdateUserType;
    get: GetUsersType,
    getPermissions: GetPermissionsType;
    deleteUser: DeleteUserType;
    updateUserPermissions: UpdateUserPermissionsType;
    createUser: CreateUserType;
    verify: VerifyUserType;
    forgotPassword: ForgotPasswordType;
}


export type UseCreateUser = [
    User[],
    Permission[],
    AuthenticationKeyFunctions
];

export const useUsers = (): UseCreateUser => {

    const [users, setUsers] = useAtom(usersAtom);
    const [permissions, setPermissions] = useAtom(permissionsAtom);
    const setLoading = useSetAtom(usersAtomLoading);

    const [,authToken, loginFunctions] = useLogin();
    const [, setSnackbar] = useSnackbar();

    const addAccount = async (body: CreateAccountRequestBody): Promise<void> => {
        const response = await Axios.post(`${environment.authenticationUrl}/create`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });

        if (!response) {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while creating your account. Please try again later.',
            })
        }
        return response.data;
    }

    const updateUser = async (body: CreateUserRequestBody): Promise<User> => {
        const response = await Axios.post(`${environment.authenticationUrl}/update`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        });

        if (!response) {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while creating your account. Please try again later.',
            })
        }
        return response.data;        
    }

    const getUsers = async (brand_id: number): Promise<User[]> => {
        setLoading(true);
        const response: AxiosResponse<User[]> | void = await Axios.get(`${environment.authenticationUrl}/users?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const users: User[] = response.data;
            setUsers(users);
            setLoading(false);
            return users;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting users. Please try again later.'
            })
            setUsers([]);
            setLoading(false);
            return [];
        }
    }

    const getPermissions = async (): Promise<Permission[]> => {
        const response: AxiosResponse<Permission[]> | void = await Axios.get(`${environment.authenticationUrl}/permissions`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const permissions: Permission[] = response.data;
            setPermissions(permissions);
            return permissions;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting permissions. Please try again later.'
            })
            setPermissions([]);
            return [];
        }
    }

    const deleteUser = async (user_id: number): Promise<User | null> => {
        const response: AxiosResponse<User> | void = await Axios.delete(`${environment.authenticationUrl}/users?user_id=${user_id}`, {            
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        
        if (response) {
            const user: User = response.data;
            setUsers(users.filter((value: User) => {
                if (value.id === user_id) {
                    return false;
                }
                return true;
            }))
            return user;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error deleting user. Please try again later.'
            })
            return null;
        }
    }
    
    const verifyUser = async (body: VerifyUserRequestBody): Promise<void> => {
        const response: AxiosResponse<Permission[]> | void = await Axios.post(`${environment.authenticationUrl}/verify-email`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            console.log('response: ', response);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while verifying user. Please try again later.'
            });
            return;
        }
    }

    const updateUserPermissions = async (body: UpdateUserRequestBody): Promise<User | null> => {
        const response: AxiosResponse<User> | void = await Axios.post(`${environment.authenticationUrl}/permissions`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const user: User = response.data;
            setUsers([...users.map((value: User) => {
                if (value.id === user.id) {
                    const permission = permissions.find((level: Permission) => {
                        if (level.id === body.permission) {
                            return true;
                        }
                        return false;
                    });
                    (value.permissions as any).permission_id = permission as Permission;
                }
                return value;
            })])
            return user;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error updating user permissions. Please try again later.'
            })
            return null;
        }
    }

    const createUser = async (body: CreateNewUserRequestBody): Promise<User | null> => {
        const response: AxiosResponse<{ user: User }> | void = await Axios.post(`${environment.authenticationUrl}/create`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const user: User = response.data.user;
            setUsers([...users, user])
            return user;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error creating user. Please try again later.'
            })
            return null;
        }
    }

    const forgotPassword = async (body: ForgotPasswordRequestBody): Promise<void> => {
        const response: AxiosResponse<User> | void = await Axios.post(`${environment.authenticationUrl}/reset-password`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            console.log('response: ', response);
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while verifying user. Please try again later.'
            });
            return;
        }
    }

    const keyFunctions: AuthenticationKeyFunctions = {
        add: addAccount,
        update: updateUser,
        get: getUsers,
        getPermissions,
        deleteUser,
        updateUserPermissions,
        createUser,
        verify: verifyUser,
        forgotPassword
    }

    return [
        users,
        permissions,
        keyFunctions
    ]
}