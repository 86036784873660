import { atom, useAtom } from "jotai";
import Axios, { AxiosResponse } from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { CompleteMultipartUploadOutput, CompletedMultipartUpload, CreateMultipartUploadOutput, HeadObjectOutput, PutObjectOutput, UploadPartOutput } from "aws-sdk/clients/s3";
import parser from 'html-react-parser';

export interface Employee {
    id: number;
    first: string;
    last: string;
    username: string;
    is_active: boolean;
}

export interface User {
    id: number;
    brand_id: number;
    email: string;
    first: string;
    last: string;
    email_verified: boolean;
    permissions: {
        id: number;
        created_at: string;
        user_id: number;
        permission_id: {
            id: number;
            created_at: string;
            permission: 'ADMIN' | 'USER' | 'TECHNICIAN' | 'SUPERUSER' | 'SUPERADMIN',
            display_name: string;
            is_publicly_visible: boolean;
        }
    }
}

export interface LoginRequestBody {
    email: string;
    password: string;
}

export interface LoginResponseBody {
    user: User | null;
    authToken: string | null;
}

export type PresignType = 'headObject' | 'getObject' | 'putObject' | 'createMultipart' | 'uploadPart' | 'completeMultipart';

export interface AWSPresignerRequestBody {
    type: PresignType;
    bucket: string;
    key: string;
    uploadId?: string;
    size?: number;
    mimetype?: string;
    partNumber?: number;
    multipart?: CompletedMultipartUpload;
}

export interface AWSPresignedURL {
    url: string;
}

export interface SendPresignedRequest {
    type: PresignType;
    url: string;
    body?: ArrayBuffer;
    bucket?: string;
    key?: string;
    uploadId?: string;
    multipart?: CompletedMultipartUpload;
}

const localUser = localStorage.getItem('user');
const user = localUser === null ? null : JSON.parse(localUser) as User;

const loggedInUserAtom = atom<User | null>(user);

const localToken = localStorage.getItem('authToken');
const authToken = localToken === null ? null : localToken;

const authTokenAtom = atom<string | null>(authToken);


type LoggedInUser = User | null;
type AuthToken = string | null;
type Login = (body: LoginRequestBody) => Promise<void>;
type Logout = () => void;
type Presigner = (body: AWSPresignerRequestBody) => Promise<AWSPresignedURL | null>;
type SendPresigned = (req: SendPresignedRequest) => Promise<CreateMultipartUploadOutput | UploadPartOutput | CompleteMultipartUploadOutput | PutObjectOutput | HeadObjectOutput | null>;

export type UseLoginKeyFunctions = {
    login: Login,
    logout: Logout,
    presigner: Presigner,
    sendPresigned: SendPresigned
}


export type UseLogin = [
    LoggedInUser,
    AuthToken,
    UseLoginKeyFunctions
]

export const useLogin = (): UseLogin => {

    const [,setSnackbar] = useSnackbar();
    const navigate = useNavigate();
    
    const [user, setUser] = useAtom(loggedInUserAtom);
    const [authToken, setAuthToken] = useAtom(authTokenAtom);

    const login: Login = async (body: LoginRequestBody) => {
        const result: AxiosResponse<LoginResponseBody> | void = await Axios.post(`${environment.authenticationUrl}/login`, body).catch((err: any) => errorCatcher(err, logout));

        if (result) {
            const data: LoginResponseBody = (result as AxiosResponse<LoginResponseBody>).data;
            if (!data.user) {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'warn',
                    text: 'Login Failed'
                });
            } else {
                localStorage.setItem('user', JSON.stringify(data.user))
                localStorage.setItem('authToken', data.authToken as string);
                setUser(data.user);
                setAuthToken(data.authToken);
            }
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Login Failed'
            });
        }
        return;
    }

    const logout: Logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        setUser(null);
        setAuthToken(null);
        navigate('/login');
    }

    const presigner: Presigner = async (body: AWSPresignerRequestBody): Promise<AWSPresignedURL | null> => {
        const result: AxiosResponse<AWSPresignedURL> | void = await Axios.post(`${environment.loginUrl}/aws-presigner`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, logout));

        if (result) {
            const data: AWSPresignedURL = (result as AxiosResponse<AWSPresignedURL>).data;
            return data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Login Failed'
            });
        }
        return null;
    }

    const sendPresigned = async (body: SendPresignedRequest): Promise<CreateMultipartUploadOutput | UploadPartOutput | CompleteMultipartUploadOutput | PutObjectOutput | HeadObjectOutput | null> => {
        if (body.type === 'completeMultipart') {
            const result: AxiosResponse<CompleteMultipartUploadOutput> | void = await Axios.post(`${environment.loginUrl}/aws-presigner`, body, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.data;
            }
            return null;
        } else if (body.type === 'createMultipart') {
            const result: AxiosResponse<CreateMultipartUploadOutput> | void = await Axios.post(body.url).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                const output: (React.JSX.Element | string)[] = parser(result.data as string) as (React.JSX.Element | string)[];
                let uploadId = '';
                output.forEach((value: string | React.JSX.Element) => {
                    if (typeof value !== 'string' && value.props.children && value.props.children.length === 3) {
                        if (typeof value.props.children[2].props.children === 'string') {
                            uploadId = value.props.children[2].props.children;
                        }
                    }
                })
                return {
                    UploadId: uploadId
                };
            }
            return null;
        } else if (body.type === 'headObject') {
            const result: AxiosResponse<HeadObjectOutput> | void = await Axios.head(body.url).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.status as any;
            }
            return null;
        } else if (body.type === 'putObject') {
            const result: AxiosResponse<PutObjectOutput> | void = await Axios.put(body.url, body.body, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            }).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.data;
            }
            return null;
        } else if (body.type === 'uploadPart') {
            const result: AxiosResponse<UploadPartOutput> | void = await Axios.put(body.url, body.body, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            }).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return { ETag: result.headers.etag.replace(/"/g, '') };
            }
            return null;
        }

        return null;
    }

    const loginKeyFunctions: UseLoginKeyFunctions = {
        login,
        logout,
        presigner,
        sendPresigned
    }

    return [
        user,
        authToken,
        loginKeyFunctions
    ]

}