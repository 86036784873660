
export interface WallmatesLogoProps {
    width?: number;
    height?: number;
}

export const WallmatesFooter: React.FunctionComponent<WallmatesLogoProps> = (props: WallmatesLogoProps) => {
    const { width, height = 80 } = props;
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 240" width={width} height={height}>
          <path className="fill-black dark:fill-white" d="M158.75,105.21v46.55c0,11.5-9.36,20.86-20.86,20.86s-20.86-9.36-20.86-20.86v-25.85c0-3.25-2.65-5.9-5.9-5.9s-5.9,2.65-5.9,5.9v25.85c0,11.5-9.36,20.86-20.86,20.86s-20.86-9.36-20.86-20.86v-46.55h14.96v46.55c0,3.25,2.65,5.9,5.9,5.9s5.9-2.65,5.9-5.9v-25.85c0-11.5,9.36-20.86,20.86-20.86s20.86,9.36,20.86,20.86v25.85c0,3.25,2.65,5.9,5.9,5.9s5.9-2.65,5.9-5.9v-46.55h14.96Z"/>
          <path className="fill-black dark:fill-white" d="M198.54,105.75c19.02,0,34.5,15.48,34.5,34.5v32.3h-14.96v-3.89c-5.56,3.84-12.29,6.09-19.54,6.09-19.02,0-34.5-15.48-34.5-34.5s15.48-34.5,34.5-34.5h0ZM218.08,140.25c0-10.78-8.77-19.54-19.54-19.54s-19.54,8.77-19.54,19.54,8.77,19.54,19.54,19.54,19.54-8.77,19.54-19.54Z"/>
          <path className="fill-black dark:fill-white" d="M240.2,172.05v-106.8h14.96v106.8h-14.96Z"/>
          <path className="fill-black dark:fill-white" d="M263.71,172.05v-106.8h14.96v106.8h-14.96Z"/>
          <path className="fill-black dark:fill-white" d="M381.95,126.15v46.55h-14.96v-46.55c0-3.25-2.65-5.9-5.9-5.9s-5.9,2.65-5.9,5.9v25.85c0,11.5-9.36,20.86-20.86,20.86s-20.86-9.36-20.86-20.86v-25.85c0-3.25-2.65-5.9-5.9-5.9s-5.9,2.65-5.9,5.9v46.55h-14.96v-46.55c0-11.5,9.36-20.86,20.86-20.86s20.86,9.36,20.86,20.86v25.85c0,3.25,2.65,5.9,5.9,5.9s5.9-2.65,5.9-5.9v-25.85c0-11.5,9.36-20.86,20.86-20.86s20.86,9.36,20.86,20.86Z"/>
          <path className="fill-black dark:fill-white" d="M421.74,105.75c19.02,0,34.5,15.48,34.5,34.5v32.3h-14.96v-3.89c-5.56,3.84-12.29,6.09-19.54,6.09-19.02,0-34.5-15.48-34.5-34.5s15.48-34.5,34.5-34.5h0ZM441.28,140.25c0-10.78-8.77-19.54-19.54-19.54s-19.54,8.77-19.54,19.54,8.77,19.54,19.54,19.54,19.54-8.77,19.54-19.54Z"/>
          <path className="fill-black dark:fill-white" d="M496.57,135.07h14.96v16.58c0,11.5-9.36,20.86-20.86,20.86s-20.86-9.36-20.86-20.86v-31.51h-11.86v-14.96h11.86v-18.61h14.96v18.61h21.13v14.96h-21.13v31.51c0,3.25,2.65,5.9,5.9,5.9s5.9-2.65,5.9-5.9v-16.58Z"/>
          <path className="fill-black dark:fill-white" d="M549.87,159.02c9.08,0,16.89-6.15,19-14.96l14.55,3.49c-3.73,15.56-17.53,26.43-33.55,26.43-19.02,0-34.5-15.48-34.5-34.5s15.48-34.5,34.5-34.5c13.5,0,25.84,7.95,31.42,20.24l3.12,6.87-48.18,21.37c3.52,3.44,8.34,5.56,13.64,5.56h0ZM530.33,139.48c0,.08,0,.16,0,.24l32.83-14.56c-3.55-3.29-8.28-5.22-13.29-5.22-10.78,0-19.54,8.77-19.54,19.54h0Z"/>
          <path className="fill-black dark:fill-white" d="M656.49,152.64c0,11.5-9.36,20.86-20.86,20.86h-45.22v-14.96h45.22c3.25,0,5.9-2.65,5.9-5.9s-2.65-5.9-5.9-5.9h-27.16c-11.5,0-20.86-9.36-20.86-20.86s9.36-20.86,20.86-20.86h45.21v14.96h-45.21c-3.25,0-5.9,2.65-5.9,5.9s2.65,5.9,5.9,5.9h27.16c11.5,0,20.86,9.36,20.86,20.86Z"/>
        </svg>
    )
}